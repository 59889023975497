type FilterKey = 'city' | 'category'

export const replaceWithBr = (text: string, isEmptyP?: boolean) => {
  if (!text) return text

  let regex = /\n/g
  if (isEmptyP) {
    regex = new RegExp('<p>&nbsp;</p>|<p>\\s</p>|<p></p>', 'g')
  }
  return text.replace(regex, '<br />')
}

export const stripeHtmlTag = (content: string = '') =>
  content.replace(/(<([^>]+)>)/gi, '')

export const timeout = (timeout = 10) => {
  return new Promise<void>((resolve) => {
    const t = setTimeout(() => {
      resolve()
      clearTimeout(t)
    }, timeout)
  })
}

export const getUniqData = (posts: any[], key: FilterKey) => {
  return posts
    .reduce<string[]>((acc, item) => {
      if (item[key] && !acc.includes(item[key].title)) {
        acc = [...acc, item[key].title]
      }

      return acc
    }, [])
    .map((d) => ({
      label: d,
      value: d,
    }))
}

export const replaceNewslineWithBr = (value: string) => {
  if (!value) return value
  return value.replace(/\n/gim, '<br/>')
}

export const removeBasePath = (path: string) => {
  if (!path) return path

  return path.replace(
    /\/app-coliving/,
    ""
  )
}

// Write a function that takes a url path and returns it after removing the last slug section.
// If the url path finishes with a slash, remove the slash and the slug before it.
// If the url path doesn't have a slash at the end, remove the last slug section.
// If the url path is empty, return an empty string.
// before returning the url path, make sure it finishes with a slash.
export const removeLastSlugSection = (urlPath: string) => {
  if (!urlPath) return "";
  if (urlPath === "") {
    return "";
  }
  const urlPathWithoutSlash = urlPath.replace(/\/$/, "");
  const urlPathSections = urlPathWithoutSlash.split("/");
  urlPathSections.pop();

  return `${removeBasePath(urlPathSections.join("/"))}/`;
};

export const removeAccents = (str: string) => {
  if (!str) return str

  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const upperCaseFirstLetter = (s: string) => {
  if (!s) return;
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};


export const parseStringToBoolean = (str: string) => {
  const lowerStr = str && str.toLowerCase();
  if (lowerStr === "true") {
    return true;
  } else if (lowerStr === "false") {
    return false;
  } else {
    return false;
  }
}

export const getResidenceAccessTokenMews = (residence: any) => {
  let residenceAccessToken = '';
  const envListByResidence = residence.envList;
  if (envListByResidence.length > 0) {
    let envInfo = envListByResidence.find((envItem: any) => envItem.content.environment == process.env.GATSBY_NODE_ENV_NAME);
    residenceAccessToken = envInfo?.content.accessTokenMews;
  }
  return residenceAccessToken;
}

export const pushDataLayerEvent = (params: any) => {
  console.log("push params", params);
  if (!!window?.dataLayer) {
    window?.dataLayer.push(params);
  }
}
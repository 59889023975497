import { createSlice } from '@reduxjs/toolkit'

const sfResidencesSlice = createSlice({
  name: 'sfResidences',
  initialState: {
    residences: [],
  },
  reducers: {
    sfResidencesInit(state, action) {
      state.residences = action.payload
    },
  },
})

// Reducers
export default sfResidencesSlice.reducer

// Selectors
export const selectSfResidencesList = (state) => state.sfResidences.residences

// Actions
export const { sfResidencesInit } = sfResidencesSlice.actions

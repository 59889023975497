import { createSlice } from '@reduxjs/toolkit'

const popinsSlice = createSlice({
  name: 'popins',
  initialState: {
    popinList: [],
  },
  reducers: {
    popinsInit(state, action) {
      state.popinList = action.payload
    },
  },
})

// Reducers
export default popinsSlice.reducer

// Selectors
export const selectPopinList = (state: any) => state.popins.popinList

// Actions
export const { popinsInit } = popinsSlice.actions

// import { navigate } from 'gatsby';

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import "./src/styles/global.css";
import WrapWithProvider from "./src/wrap-with-provider";
import { navigate } from "gatsby";

export const onRouteUpdate = ({ location, prevLocation }) => {
  let currentLocation = Cookies.get("currentLocation")
  let lastLocation = Cookies.get("lastLocation")
  let accessToken = Cookies.get("accessToken")
  let hasValidToken = false;

  const hash = typeof window != 'undefined' ? window.location.hash : '';
  if (hash.includes('#id_token=')) {
    const tokenStart = hash.indexOf('#id_token=') + '#id_token='.length;
    const tokenEnd = hash.indexOf('&', tokenStart);
    const token = tokenEnd !== -1 ? hash.substring(tokenStart, tokenEnd) : hash.substring(tokenStart);

    // Validate the JWT token
    const decodedToken = jwt_decode(token);
    console.log("decodedToken : ", decodedToken);
    if (decodedToken && decodedToken?.user_metadata?.idMews) {
      hasValidToken = true;
    }
  }

  if(!!currentLocation && currentLocation != '' && (!!accessToken || hasValidToken)) {
    Cookies.remove("currentLocation")
    navigate(currentLocation, { replace: false,  },);
    return;
  }else if(!!lastLocation){
    Cookies.remove("lastLocation")
    navigate(lastLocation, { replace: false,  },);
  }
  if (prevLocation && location.pathname !== prevLocation.pathname) {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    const isModal = sessionStorage.getItem('modal');
    if (scrollPosition !== null && isModal == null ) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
      sessionStorage.removeItem('scrollPosition');
    } else {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
      sessionStorage.removeItem('modal');
    }
  }
};

// export const wrapRootElement = ({ element }) => element
export const wrapRootElement = WrapWithProvider;

import React, { useState } from 'react'
import browserLang from 'browser-lang'
import { navigate } from 'gatsby'
import { IntlProvider } from 'react-intl'
import { IntlContextProvider } from './intl-context'
import { isMatch } from './util'
const preferDefault = m => (m && m.default) || m

const polyfillIntl = language => {
  const locale = language.split('-')[0]
  try {
    if (!Intl.PluralRules) {
      require('@formatjs/intl-pluralrules/polyfill')
      require(`@formatjs/intl-pluralrules/dist/locale-data/${locale}`)
    }

    if (!Intl.RelativeTimeFormat) {
      require('@formatjs/intl-relativetimeformat/polyfill')
      require(`@formatjs/intl-relativetimeformat/dist/locale-data/${locale}`)
    }
  } catch (e) {
    throw new Error(`Cannot find react-intl/locale-data/${language}`)
  }
}

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const InitProvider = ({ intl, children }) => {
  const [messages, setMessages] = useState({})
  const [fetchNamespaces, setFetchNamespaces] = useState({})

  const addMessages = newMsg => {
    const availableKeys = Object.keys(newMsg).filter(
      k => newMsg[k] !== messages[k]
    )
    if (availableKeys.length > 0) {
      setMessages(msg => {
        return {
          ...msg,
          ...newMsg,
        }
      })
    }
  }

  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLanguage}
      messages={flattenMessages(messages)}
    >
      <IntlContextProvider
        value={{ ...intl, addMessages, fetchNamespaces, setFetchNamespaces }}
      >
        {children}
      </IntlContextProvider>
    </IntlProvider>
  )
}

const withIntlProvider = intl => children => {
  polyfillIntl(intl.language)
  return <InitProvider intl={intl} children={children} />
}

const WrapPage = ({ element, props }, pluginOptions) => {
  if (!props) {
    return
  }

  const { pageContext, location } = props
  const { defaultLanguage } = pluginOptions
  const { intl } = pageContext
  const {
    language,
    languages,
    redirect,
    routed,
    originalPath,
    redirectDefaultLanguageToRoot,
    ignoredPaths,
    fallbackLanguage,
  } = intl

  if (typeof window !== 'undefined') {
    window.___gatsbyIntl = intl
  }
  /* eslint-disable no-undef */
  let isRedirect = redirect && !routed

  if (isRedirect) {
    const { search } = location

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      let detected =
        window.localStorage.getItem('gatsby-intl-language') ||
        browserLang({
          languages,
          fallback: fallbackLanguage || language,
        })

      if (!languages.includes(detected)) {
        detected = language
      }
      const isMatchedIgnoredPaths = isMatch(
        ignoredPaths,
        window.location.pathname
      )
      isRedirect =
        !(redirectDefaultLanguageToRoot && detected === defaultLanguage) &&
        !isMatchedIgnoredPaths

      if (isRedirect) {
        const queryParams = search || ''
        const newUrl = `/${detected}${originalPath}${queryParams}`
        window.localStorage.setItem('gatsby-intl-language', detected)

        navigate(newUrl, {
          replace: true,
        })
        // browser should render redirect element
        const renderElement =
          GATSBY_INTL_REDIRECT_COMPONENT_PATH &&
          React.createElement(
            preferDefault(require(GATSBY_INTL_REDIRECT_COMPONENT_PATH))
          )
        return withIntlProvider(intl)(renderElement)
      }
    }
  }
  const renderElement =
    isRedirect && !redirectDefaultLanguageToRoot
      ? GATSBY_INTL_REDIRECT_COMPONENT_PATH &&
        React.createElement(
          preferDefault(require(GATSBY_INTL_REDIRECT_COMPONENT_PATH))
        )
      : element
  return withIntlProvider(intl)(renderElement)
}

export default WrapPage
